//-------------Tags---------------------
.redesignActive {
  .ant-tag {
    color: black;
    border-radius: 17px;
    font-weight: 400;
    border-color: transparent;
    font-family: AkkuratLLWeb-Regular;
    font-size: 13px;
    background-color: #f1f1f1;

  }
}
//-------------Tags---------------------
