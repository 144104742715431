body.peak-shaving .ant-layout-content {
  padding: 19px 20px 8px 20px !important;
}

.createNewProgramModal .ant-modal-footer,
.archive-program-modal .ant-modal-footer,
.warning-modal .ant-modal-footer {
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
}

.createNewProgramModal textarea[placeholder='Description'] {
  border-radius: 20px;
  height: 103px;
}

.createNewProgramModal {
  & .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  & .ant-form-item {
    margin-bottom: 21.5px;
  }
}

.createNewProgramModal label > p {
  margin-bottom: 0;
}

.ant-modal h5.heading-05-regular {
  margin-bottom: 0;
}

.add-listings-to-program-modal .ant-modal-body {
  padding-bottom: 12px;
}

.createNewProgramModal .ant-modal-header {
  border-bottom: 1px solid #dedede;
}

.createNewProgramModal {
  #name, #participantId {
    border: 1px solid #dedede;
    border-radius: 20px;
    min-height: 39px;
  }
}

textarea.ant-input {
  border-radius: 20px !important;
}

.createNewProgramModal #name.ant-input.ant-input-status-error, #participantId.ant-input.ant-input-status-error {
  border-color: #ec4747;
}

.createNewProgramModal .ant-select-selector {
  min-height: 39px;
}

.createNewProgramModal .ant-select-selection-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.createNewProgramModal #description {
  border-radius: 20px;
}

.createNewProgramModal .ant-select-arrow svg,
.createNewEventModalContainer .ant-select-arrow svg,
.createNewScheduleModalContainer .ant-select-arrow svg,
.createNewScheduleModalContainer .ant-select-arrow svg,
.add-listings-to-program-modal .ant-select-arrow svg,
.duplicateEventModalContainer .ant-select-arrow svg,
.editDailyScheduleModalContainer .ant-select-arrow svg,
.category .ant-select-arrow svg {
  rotate: 180deg;
  transition: all 500ms ease;
}

.createNewScheduleModalContainer .ant-picker-suffix,
.createNewEventModalContainer .ant-picker-suffix,
.duplicateEventModalContainer .ant-picker-suffix,
.editDailyScheduleModalContainer .ant-picker-suffix,
.editDailyScheduleModalContainer .ant-picker-suffix {
  display: block !important;
}

.createNewScheduleModalContainer,
.createNewEventModalContainer,
.duplicateEventModalContainer,
.editDailyScheduleModalContainer,
.editDailyScheduleModalContainer {
  & .ant-modal-title > .heading-05-regular {
    margin-bottom: 0;
  }

  & .ant-modal-footer > button {
    display: none;
  }

  & .ant-modal-footer .ant-btn {
    width: 175px;
  }

  & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -5px;
    font-size: 13px;
    font-family: 'AkkuratLLWeb-Regular';
    font-weight: 400;
    line-height: 19px;
  }
}

.createNewProgramModal .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open svg,
.createNewEventModalContainer
  .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
  svg,
.createNewScheduleModalContainer
  .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
  svg,
.createNewScheduleModalContainer
  .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
  svg,
.add-listings-to-program-modal
  .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
  svg,
.duplicateEventModalContainer
  .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
  svg,
.category .ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open svg {
  rotate: 0deg;
}

.createNewEventModalContainer .ant-form-item-control-input,
.duplicateEventModalContainer .ant-form-item-control-input,
.createNewScheduleModalContainer .ant-form-item-control-input,
.editDailyScheduleModalContainer .ant-form-item-control-input {
  min-width: 141px;
  min-height: 39px;
}

.createNewScheduleModalContainer .ant-tabs,
.editDailyScheduleModalContainer .ant-tabs {
  width: 100%;
}

.createNewProgramModal .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}

.createNewProgramModal
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: -15px;
}

.table-dot-dropdown {
  border-radius: 5px;
  padding: 5px 10px;

  li {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  li:not(:first-child) {
    padding-top: 7.5px
  }

  p {
    margin-bottom: 0px;
  }
}

.edit-schedule-dropdown,
.edit-event-dropdown {
  border-radius: 5px;
  height: 73.05px;
  width: 171px;
  padding: 10px 15px;
}

.edit-schedule-dropdown li,
.edit-event-dropdown li {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.edit-schedule-dropdown li:hover,
.edit-event-dropdown li:hover {
  background-color: unset;
}

.edit-event-dropdown p,
.edit-schedule-dropdown p {
  margin-bottom: 0;
}

.edit-event-dropdown li:first-of-type,
.edit-schedule-dropdown li:first-of-type {
  padding: 0px 0px 7.5px 0px;
}
.edit-event-dropdown li:nth-of-type(2),
.edit-schedule-dropdown li:nth-of-type(2) {
  padding: 7.5px 0 0 0;
}

.edit-event-dropdown .cancel-event {
  color: #7d838a;
}

.edit-event-dropdown .ant-dropdown-menu-item-icon {
  margin-right: 10px;
}

.createNewEventModalContainer .ant-modal-close {
  top: 5px;
}

.createNewEventModalContainer .ant-modal-header {
  border-bottom: 1px solid #dedede;
}

.createNewEventModalContainer input.ant-input,
.createNewScheduleModalContainer input.ant-input,
.duplicateEventModalContainer input.ant-input,
.editDailyScheduleModalContainer input.ant-input {
  width: 141px;
  height: 39px;
  border-radius: 33px;
}

#aggregatorName.ant-input {
  height: unset
}

.createNewEventModalContainer textarea.ant-input,
.createNewScheduleModalContainer textarea.ant-input,
.duplicateEventModalContainer textarea.ant-input,
.editDailyScheduleModalContainer textarea.ant-input {
  width: 252px;
  height: 100px;
  border-radius: 20px;
}

.createNewEventModalContainer .ant-modal-footer,
.createNewScheduleModalContainer .ant-modal-footer,
.duplicateEventModalContainer .ant-modal-footer {
  padding: 20px 16px;
  border-top: 1px solid #dedede;
}

.ant-row.ant-form-item.zone > .ant-col {
  margin-left: 0;
}

.ant-row.ant-form-item.zone .ant-form-item-control-input {
  min-width: 224px;
  min-height: 39px;
}

.notes .ant-form-item-control-input {
  min-width: 252px;
}

.add-btn .ant-col {
  margin-left: 0;
}

.zone .ant-select.ant-select-single.ant-select-show-arrow > .ant-select-selector {
  height: 39px;
  width: 224px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#program,
#frequency,
.editDailyScheduleModalContainer #updatedBy {
  padding: 4px 8px;
  background-color: #0000000d;
  border-radius: 17px;
  display: block;
  width: max-content;
  margin-bottom: 10px;
}

#allowOptOut[aria-checked='true'] {
  background-color: #30d7ba;
}

.eventDate .ant-picker {
  width: 288px;
  height: 39px;
  border-radius: 20px;
}
#eventDate {
  position: relative;
}
#eventDate .ant-space-item > svg {
  position: absolute;
  right: 25px;
  bottom: 8px;
}

.ant-modal.archive-program-modal .ant-modal-content,
.ant-modal.warning-modal .ant-modal-content,
.ant-modal.cancel-event-modal .ant-modal-content {
  width: 441px;
  height: 184px;
}

.ant-modal.archive-program-modal .ant-modal-header,
.ant-modal.remove-program-modal .ant-modal-header,
.ant-modal.warning-modal .ant-modal-header,
.ant-modal.cancel-event-modal .ant-modal-header {
  padding: 20px 158px 11px 158px;
  border-bottom: unset;
  text-align: center;
}

.ant-modal.warning-modal.remove-schedule-modal .ant-modal-header {
  padding: 20px 150px 11px 150px;
}

.ant-modal.archive-program-modal .ant-modal-body,
.ant-modal.warning-modal .ant-modal-body {
  padding: 0 30px;
  text-align: center;
}

.ant-modal.archive-program-modal .ant-modal-title,
.ant-modal.warning-modal .ant-modal-title {
  font-size: 16px;
}

.ant-modal.archive-program-modal .ant-modal-body .heading-06-regular,
.ant-modal.warning-modal .ant-modal-body .heading-06-regular {
  margin-bottom: 18px;
}

.createNewScheduleModalContainer .ant-tabs-nav,
.editDailyScheduleModalContainer .ant-tabs-nav {
  position: absolute;
  top: 0;
  right: 0;
}

.add-listings-to-program-modal .ant-modal-header {
  border-bottom: 1px solid #dedede;
}

.add-listings-to-program-modal .ant-modal-footer {
  border-top: 1px solid #dedede;
  padding: 25px 25px;
  border-bottom: 0;
}

.add-listings-to-program-modal
  .ant-select.ant-select-auto-complete.ant-select-single.ant-select-customize-input.ant-select-show-search {
  margin-bottom: 16px;
}

.add-listings-to-program-modal .ant-modal-body > p {
  margin-bottom: 0;
  line-height: 19px;
}

.add-listings-to-program-modal .ant-tag {
  background-color: @cultered;
  padding: 3px 10px;
  position: relative;
  min-width: 65px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-listings-to-program-modal .error-mssg {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  left: 5%;
  top: 50%;
}

.add-listings-to-program-modal .ant-input-affix-wrapper:hover {
  border-color: @primary !important;
}

.add-listings-to-program-modal.ant-modal-error .ant-input-affix-wrapper {
  border-color: @redBase;
  box-shadow: 0 0 0 2px rgba(222, 84, 84, 0.1);
}

.add-listings-to-program-modal .ant-input-clear-icon {
  display: none;
}

.add-listings-to-program-modal .error-mssg p {
  color: @redBase;
  margin-left: 6px;
}

.add-listings-to-program-modal .ant-input-affix-wrapper {
  border-radius: 41px;
}

.add-listings-to-program-modal svg[data-icon='search'] {
  display: none;
}

.add-listings-to-program-modal .ant-input-affix-wrapper + svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: rotate(180deg);
  z-index: 100;
  transition: all 500ms ease;
}

.add-listings-to-program-modal .ant-input-affix-wrapper.ant-input-affix-wrapper-focused + svg {
  transform: rotate(0deg);
}

.ant-modal.remove-program-modal .ant-modal-content {
  width: 441px;
}

.ant-modal.remove-program-modal .ant-modal-content > .ant-modal-title {
  margin-bottom: 0;
}

.ant-modal.remove-program-modal .ant-modal-body {
  padding-top: 0;
  text-align: center;
  padding-bottom: 10px;
}

.ant-modal.remove-program-modal,
.ant-modal.archive-program-modal,
.ant-modal.remove-schedule-modal,
.ant-modal.cancel-event-modal {
  & .ant-btn {
    width: 198px;
  }

  .ant-btn.ant-btn-primary {
    background-color: @redBase;
    border-color: @redBase;
  }
  .ant-modal-header h6 {
    margin-bottom: 0;
  }
}

.ant-modal.cancel-event-modal {
  & .ant-modal-content {
    height: auto;
  }
  & .ant-modal-body {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.ant-message-notice.success-message .ant-message-custom-content.ant-message-success {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-message-notice.success-message .paragraph-01-regular {
  margin-bottom: 0;
}

.ant-message-notice.success-message .ant-message-custom-content.ant-message-success > svg {
  margin-right: 10px;
}

.ant-message-notice.success-message .ant-message-notice-content {
  background: @primary;
  border-radius: 5px;
}

.redesignActive .ant-message {
  bottom: 0;
  top: unset;
  right: 0;
  left: unset;
  width: auto;
}

.ant-picker-time-panel + .ant-picker-footer {
  display: block !important;
}

.ant-picker-time-panel + .ant-picker-footer .ant-picker-ranges {
  bottom: 0;
  z-index: 1000;
  background: white;
}

.ant-picker.timepicker {
  width: 120px;
  border-radius: 20px;
}

.program .ant-form-item-control-input {
  width: 250px;
}

.peak-shaving {
  & div[aria-selected='true'] .ant-select-item-option-content::after {
    position: absolute;
    right: 10px;
    top: 5px;
    @svg-color: escape(@primary);
    content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 5L6.54217 13L4 10.273' stroke='@{svg-color}' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
  }
}

.ant-modal-error .ant-modal-content {
  width: auto !important;
  height: auto !important;
}

.peak-shaving-plus-icon {
  cursor: pointer;
  circle {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover {
    circle {
      fill: @button-hovering;
    }
  }
}

.ant-badge.archived sup {
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #7d838a !important;
}

/************************FR***********************/
.fr {
  & .ant-modal.archive-program-modal .ant-modal-content,
  & .ant-modal.warning-modal .ant-modal-content,
  & .edit-schedule-dropdown,
  & .edit-event-dropdown {
    width: auto;
    height: auto;
  }
}
