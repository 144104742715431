// modal.less
.fixed-row-modal .ant-modal-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }

  .fixed-row-modal .ant-modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: white;
    width: 100%;
  }

  /* Remove the bottom border of the modal's header */
.remove-bottom-border .ant-modal-header {
  border-bottom: none;
  padding: 20px 25px 0 25px;
}


/* Additional styles for the collapsible arrow transition */
.custom-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-icon {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.rotate-icon {
  transform: rotate(180deg);
}

.default-modal {
  
  .ant-input, .ant-select-selector {
    border-radius: 20px !important;
    min-height: 38px;

  }

  .ant-select-selection-overflow {
    margin-left: 0px !important;
  }

  .ant-input-disabled:hover{
    border-color: @light-gray;
  }

  
}
.max-height-modal {
  .ant-modal-content {
    max-height: 80vh;
    overflow: scroll;
  }
}