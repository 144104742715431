@import './select-inputs.less';

//-------------Tenant Selector---------------------
.redesignActive {
  .ant-checkbox + span {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #242e2c;
  }

  .ant-checkbox-inner {
    border-radius: 5px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @button-primary;
    border-color: @button-primary;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid @black;
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-checked::after {
    border: 0;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5;
  }

  // ! whether to show the checkmark in the select dropdown
  span.ant-select-item-option-state {
    display: none;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-select-item.ant-select-item-option:hover .ant-checkbox-inner {
    border-color: @button-primary;
  }

  .ant-select-item.ant-select-item-option[aria-selected='false']:hover .ant-checkbox-inner {
    border-color: #c1c3d0;
    background-color: #f1f1f1;
  }

  .tenant-selector .removeIcon {
    width: 7px;
    height: 7px;
    background: none;
  }

  .tenant-selector .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    margin-left: 5px;
  }

  .tenant-selector span.ant-select-clear {
    opacity: 1 !important;
    background: none !important;
    top: 47% !important;
  }
}
//-------------Tenant Selector---------------------
